import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ContainerBox from "../../components/containerBox";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import CodeOfEthicsJumbotron from "../../components/jumbotron/CodeOfEthics";

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      paddingTop: 160,
      paddingBottom: 160,
    },
    title: {
      fontSize: "46px",
      fontWeight: 700,
      lineHeight: "1.2",
      marginBottom: 16,
    },
    text: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: "-0.5px",
      marginBottom: 160,
      color: "#858585",
    },
    markContainer: {
      marginTop: 30,
      marginBottom: 30,
      boxSizing: "border-box",
      float: "left",
    },
    markImage: {
      marginBottom: 32,
    },
    href: {
      color: "#00DD99",
      textDecoration: "none",
    },
  })
);

interface MilestonePageProps {
  pageContext: {
    language: string;
  };
}
const translate: any = {
  desc1: {
    en:
      "Hyperconnect, as an affiliate of Match group, is an organization with strong values of responsibility and integrity. Our code of Business Conduct & Ethics contains general guidelines for conducting business with the highest standards of ethics. You can access the version of our code of business conduct & ethics that applies to you by clicking the link here",
    ko:
      "하이퍼커넥트는 매치그룹의 일원으로서, 윤리적인 책임감과 청렴함(Integrity)을 무엇보다 중요한 가치로 삼습니다. 하이퍼커넥트의 윤리행동강령은 사업을 수행함에 있어 발생할 수 있는 상황에 이정표가 될 수 있는 전반적인 가이드라인을 제시하고 있습니다. 하이퍼커넥트의 윤리행동강령 세부내역을 확인하고 싶으시다면 언제든지 링크를 통해 확인하실 수 있습니다 ",
  },
  desc2: {
    en:
      "Hyperconnect is committed to an environment where open, honest communication is the expectation, not the exception. We want every employees to feel comfortable in approaching your supervisor or management where you believe violations of policies or standards have occured.",
    ko:
      "또한, 하이퍼커넥트의 모든 임직원들은 열린 소통, 정직한 소통이 주는 가치를 인식하고 실천합니다. 열린 소통, 정직한 소통은 모든 임직원에게 기대되는 저희의 핵심 가치이며 누구에게도 예외를 두지 않습니다. 하이퍼커넥트는 모든 임직원들이 규정이나 기준이 위반되는 상황에 마주하게 되었을 때 상급자 또는 경영진에게 신속하게 내용을 전달하는 데 부담을 가지지 않기를 바랍니다.",
  },
};

function IntellectualPropertyPage({ pageContext }: MilestonePageProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;

  return (
    <Layout language={language}>
      <SEO title="History" />
      <CodeOfEthicsJumbotron />
      <ContainerBox className={classes.container}>
        <Grid container>
          <Grid item sm={12}>
            <Typography variant="h2" className={classes.title}>
              Our Commitment
            </Typography>
            <Typography component="p" className={classes.text}>
              {translate.desc1[language]}
              <a href={"/pdf/Code%20of%20Ethics.pdf"} className={classes.href}>
                {" "}
                (link)
              </a>
              <br />
              <br />
              {translate.desc2[language]}
            </Typography>
          </Grid>
        </Grid>
      </ContainerBox>
    </Layout>
  );
}

export default IntellectualPropertyPage;
